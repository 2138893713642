<template>
	<v-row>
		<v-col cols="12">
			<v-card>
				<v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
					<v-card color="primary" dark>
						<v-card-text class="pt-3">
							Aguarde enquanto o arquivo é gerado!
							<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
						</v-card-text>
					</v-card>
				</v-dialog>
				<v-dialog v-model="dialogErro.status" persistent>
					<CardDialog :dialog="dialogErro" />
				</v-dialog>
				<v-card-title>
					Boletos Gerados
					<v-spacer />
					<v-btn color="primary" elevation="0" @click="gerarArquivo()">
						<v-icon class="pr-1">mdi-content-save-outline</v-icon>Salvar
					</v-btn>
				</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th></th>
									<th class="text-left">Filial</th>
									<th class="text-left">Cliente</th>
									<th class="text-left">Título</th>
									<th class="text-left">Digito</th>
									<th class="text-left">Título banco</th>
									<th class="text-left">Data de movimento</th>
									<th class="text-left">Data de vencimento</th>
									<th class="text-left">Valor do título</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<template v-if="dados.length > 0">
									<tr v-for="(u, i) in dados" :key="i">
										<td>
											<v-checkbox v-model="boletos" :value="u"></v-checkbox>
										</td>
										<td class="text-truncate">{{u.idfilial}} - {{u.filial}}</td>
										<td>{{u.idcliente}} - {{u.cliente}}</td>
										<td>{{u.titulo}}</td>
										<td>{{u.digito}}</td>
										<td>{{u.idtitulobanco}}</td>
										<td>{{u.dtlancamento}}</td>
										<td>{{u.dtvencimento}}</td>
										<td>{{u.valorboleto | formataDinheiro}}</td>
										<td class="text-center">
											<v-btn
												v-if="u.flagexportado == 'N' || u.flagexportado == 'E'"
												:disabled="carregando"
												elevation="0"
												color="error"
												fab
												x-small
												@click="excluirBoleto(u)"
											>
												<v-icon>mdi-delete-outline</v-icon>
											</v-btn>
										</td>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td class="text-center" colspan="10">Nenhum registro encontrado.</td>
									</tr>
								</template>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
		<v-col cols="12">
			<v-card>
				<v-card-title>Arquivos de Remessa Gerados</v-card-title>
				<v-divider />
				<v-progress-linear indeterminate absolute :active="carregando" />
				<v-card-text>
					<v-simple-table>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left text-truncate">Filial</th>
									<th class="text-center text-truncate">Arquivo</th>
									<th class="text-center text-truncate">Data de movimento</th>
									<th class="text-center text-truncate">Número de registros</th>
									<th></th>
								</tr>
							</thead>
							<tbody>
								<template v-if="arquivos.length > 0">
									<tr v-for="(u, i) in arquivos" :key="i">
										<td class="text-truncate">{{u.idfilial}} - {{u.filial}}</td>
										<td class="text-center">{{u.arquivo}}</td>
										<td class="text-center">{{u.dtmovimento}}</td>
										<td class="text-center">{{u.numregistros}}</td>
										<td class="text-truncate text-center">
											<v-btn
												:disabled="carregando"
												class="px-2"
												elevation="0"
												color="primary"
												small
												@click="baixarArquivo(u)"
											>
												<v-icon class="pr-1">mdi-download-outline</v-icon>Baixar arquivo
											</v-btn>
										</td>
									</tr>
								</template>
								<template v-else>
									<tr>
										<td class="text-center" colspan="5">Nenhum registro encontrado.</td>
									</tr>
								</template>
							</tbody>
						</template>
					</v-simple-table>
				</v-card-text>
				<v-progress-linear indeterminate absolute :active="carregando" />
			</v-card>
		</v-col>
	</v-row>
</template>


<script>
import axios from "axios";
import { mapState } from "vuex";
import CardDialog from "../Widgets/CardDialog";

export default {
	name: "ParcelamentoRemessa",
	components: { CardDialog },
	data: () => ({
		carregando: false,
		dialogLoading: false,
		dialogErro: {
			status: false,
			title: "",
			msg: "",
			icon: "",
			iconColor: "",
		},
		dados: {},
		boletos: [],
		arquivos: [],
	}),
	computed: {
		...mapState(["backendUrl", "usuario"]),
	},
	methods: {
		listar() {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}remessabancaria/listar`)
				.then((res) => {
					this.dados = res.data;
					this.boletos = [];
					this.carregando = false;
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		listarArquivos() {
			return axios
				.post(`${this.backendUrl}remessabancaria/arquivo/listar`)
				.then((res) => {
					this.arquivos = res.data;
				});
		},
		gerarArquivo() {
			if (this.boletos.length == 0) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Nenhum boleto selecionado para gerar o arquivo!";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			if (this.boletos.length > 27) {
				this.dialogErro.title = "Erro";
				this.dialogErro.msg =
					"Número máximo de boletos por arquivo excedido! (Máx. 27 boletos)";
				this.dialogErro.icon = "mdi-alert-circle-outline";
				this.dialogErro.iconColor = "red";
				this.dialogErro.status = true;
				return;
			}
			let boletos = this.boletos.map((v) => {
				return {
					idfilial: v.idfilial,
					idcliente: v.idcliente,
					titulo: v.titulo,
					digito: v.digito,
				};
			});
			this.carregando = true;
			this.dialogLoading = true;
			return axios
				.post(`${this.backendUrl}remessabancaria/gerar`, boletos)
				.then((res) => {
					this.carregando = false;
					this.dialogLoading = false;
					if (res.data) {
						const url = window.URL.createObjectURL(
							this.base64ToBlob(res.data.arquivo)
						);
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", `${res.data.nome}.txt`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
						this.listar();
						this.listarArquivos();
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Erro ao gerar o arquivo de remessa!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogLoading = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Erro ao gerar o arquivo de remessa!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		excluirBoleto(boleto) {
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}parcelamento/boleto/excluir`, {
					idfilial: boleto.idfilial,
					idcliente: boleto.idcliente,
					titulo: boleto.titulo,
					digito: boleto.digito,
					serie: boleto.serie,
				})
				.then((res) => {
					this.carregando = false;
					if (res.data) {
						this.listar();
						this.listarArquivos();
						this.dialogErro.title = "Boleto excluído";
						this.dialogErro.msg = "Boleto excluído com sucesso!";
						this.dialogErro.icon = "mdi-check-circle-outline";
						this.dialogErro.iconColor = "success";
						this.dialogErro.status = true;
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg = "Erro ao excluir o boleto!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg = "Erro ao excluir o boleto!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		baixarArquivo(arquivo) {
			this.carregando = true;
			this.dialogLoading = true;
			return axios
				.post(`${this.backendUrl}remessabancaria/download`, {
					idarquivo: arquivo.arquivo,
				})
				.then((res) => {
					this.carregando = false;
					this.dialogLoading = false;
					if (res.data) {
						const url = window.URL.createObjectURL(
							this.base64ToBlob(res.data.arquivo)
						);
						const link = document.createElement("a");
						link.href = url;
						link.setAttribute("download", `${res.data.nome}.txt`);
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					} else {
						this.dialogErro.title = "Erro";
						this.dialogErro.msg =
							"Erro ao baixar o arquivo de remessa de boletos!";
						this.dialogErro.icon = "mdi-alert-circle-outline";
						this.dialogErro.iconColor = "red";
						this.dialogErro.status = true;
					}
				})
				.catch((e) => {
					this.carregando = false;
					this.dialogLoading = false;
					this.dialogErro.title = "Erro";
					if (e.response.data.erro) {
						this.dialogErro.msg = e.response.data.erro;
					} else {
						this.dialogErro.msg =
							"Erro ao baixar o arquivo de remessa de boletos!";
					}
					this.dialogErro.icon = "mdi-alert-circle-outline";
					this.dialogErro.iconColor = "red";
					this.dialogErro.status = true;
				});
		},
		async init() {
			await this.listar();
			await this.listarArquivos();
		},
	},
	created() {
		this.init();
	},
};
</script>