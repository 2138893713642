<template>
	<v-card class="pa-5">
		<v-card-title class="text-h4">
			<span class="mx-auto">{{dialog.title}}</span>
		</v-card-title>
		<div class="text-center py-3">
			<v-icon size="100" :color="dialog.iconColor">{{dialog.icon}}</v-icon>
		</div>
		<v-card-text class="text-h6 text-center">{{dialog.msg}}</v-card-text>
		<v-card-actions>
			<v-spacer />
			<v-btn color="primary" elevation="0" class="px-10" @click="dialog.status = false">OK</v-btn>
			<v-spacer />
		</v-card-actions>
	</v-card>
</template>

<script>
export default {
	name: "CardDialog",
	props: ["dialog"],
};
</script>